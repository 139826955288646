import styled from '@emotion/styled'
import Image from 'components/Image'
import { useState, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import FlexBox from 'components/FlexBox'
import useSize from 'hooks/useSize'
import { useNavigate } from 'react-router'

const FirstScreen = () => {

  gsap.registerPlugin(ScrollTrigger)
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      const screenWidth = window.innerWidth
      const screenHeight = window.innerHeight

      const vw = screenWidth / 100
      const vh = screenHeight / 100

      const translationOneH = 45 * vh
      const translationThreeH = 105 * vh
      const translationTwoH = 135 * vh

      const translationTwoX = -10 * vw
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.test-wrap',
          start: 'top top',
          end: `+=${translationTwoH}`,
          scrub: 0.62,
        },
      })

      tl.to('.LogoImg', { y: translationOneH, duration: 4 })
      tl.to('.LogoImg', { rotation: 360, scale: 2, x: translationTwoX, y: translationThreeH, duration: 10 })
      tl.fromTo(`.biglogo`, { opacity: 0, y: -30 }, { opacity: 1, y: 0, duration: 1 })



      const st = ScrollTrigger.create({
        trigger: '.test-wrap',
        start: 'bottom bottom',
        end: '125%',
        pin: true,
        pinSpacing: false,
      })

      return () => {
        tl.kill()
        // st.kill()
      }
    }
  }, [pcSize])

  const [isBlack, setIsBlack] = useState<any>(true)

  useEffect(() => {
    function handleScroll() {
      // 获取当前滚动高度
      var scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      const screenHeight = window.innerHeight
      const vh = screenHeight / 100

      // 检查滚动高度是否达到阈值
      if (scrollHeight >= 130 * vh) {
        if (isBlack) {
          setIsBlack(false)
        }
      } else {
        if (!isBlack) {
          setIsBlack(true)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    // 在组件卸载时清除监听器
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isBlack])

  useEffect(() => {
    // 创建 GSAP 动画
    const tl = gsap.timeline({ repeat: -1, yoyo: true })
    tl.to('.belowimg', { duration: 0.3, y: 5 })

    // 在组件卸载时清除动画
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <>
      <Wrap className="test-wrap" id="games">
        {/* <LogoImg folder="index2/FirstScreen" className="LogoImg" src="logoImg.png" /> */}
        <Logo folder="index2/FirstScreen" src="marsxyz.png" />

        <StickyScene>
          <FlexBox
            between
            sx={{
              width: '73.542vw',
              height: '75.2vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            className="stickyscene"
          >
            <LeftBox>
              {/* <BigLogoImg folder="index2/FirstScreen" src="logoImg.png" /> */}
              <BigLogo className="biglogo" folder="index2/FirstScreen" src="marsxyz.png" />
            </LeftBox>
            <ContentOuter>
              <Title className="exit-animation">Welcome to MarsXYZ</Title>
              <SmallTitle className="exit-animation">MarsXYZ is an organization that explores Mars ecology and future space economy, involving Mars immigration, space economy and technological development including AI, Game, IM communications, cryptocurrency, etc.</SmallTitle>

              <Tips className="exit-animation">Skytopia is a web3 entertainment brand that connects games and communities. It combines different forms of entertainment like gaming, animation, novels to create an immersive entertainment metaverse for users.</Tips>
              <Tips className="exit-animation">Based on Web3 technology, we hope to break down the barriers between creators and consumers. Let's build the Skytopia Universe together.</Tips>
            </ContentOuter>
          </FlexBox>
        </StickyScene>
      </Wrap>
      
      <BelowBox style={{ opacity: isBlack ? 1 : 0 }}>
        <BelowImg className="belowimg" folder="index2/GameDownload" src="below.png" />
        <BelowVal>SCROLL</BelowVal>
      </BelowBox>
    </>
  )
}
const Wrap = styled.div`
  width: 100vw;
  height: 215vh;
  position: relative;
  overflow: hidden;
  margin-bottom: 70vh;
`
const LogoImg = styled(Image)`
  width: 262.003px;
  height: 286px;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: right top;
`
const Logo = styled(Image)`
  width: 390px;
  height: 390px;
  position: absolute;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
`

const StickyScene = styled.div`
  position: absolute;
  bottom: 0vw;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

const LeftBox = styled.div`
  width: 36.458vw;
  height: 28.542vw;
  margin-top: 0.833vw;
  position: relative;
`

const BigLogoImg = styled(Image)`
  width: 27.292vw;
  height: 29.792vw;
  position: absolute;
  top: 0.417vw;
  left: 7.396vw;
  opacity: 0;
`
const BigLogo = styled(Image)`
  width: 30.625vw;
  height: 30.625vw;
  position: absolute;
  left: 0%;
  top: 1.469vw;
`

const ContentOuter = styled.div`
  width: 31.823vw;
  height: auto;
`
const Title = styled.div`
  width: 24.74vw;
  min-height: 5.313vw;
  font-size: 4.167vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 500;
  color: #3d3d3d;
  line-height: 4.688vw;
  margin-bottom: 0.885vw;
`
const SmallTitle = styled.div`
  margin-bottom: 0.885vw;
  height: 10.51vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 1.563vw;
`
const Tips = styled.div`
  width: 31.823vw;
  min-height: 3.229vw;
  font-size: 0.938vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #7b7b7b;
  line-height: 1.563vw;
  margin-bottom: 0.885vw;
`

const BelowBox = styled.div`
  width: 4.063vw;
  height: 3.229vw;
  position: fixed;
  bottom: 4.167vw;
  left: 47.865vw;
  z-index: 1111;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const BelowImg = styled(Image)`
  width: 0.833vw;
  height: 0.833vw;
  display: block;
`
const BelowVal = styled.div`
  width: 4.063vw;
  height: 1.563vw;
  font-size: 0.625vw;
  font-family: Albert Sans-Regular, Albert Sans;
  color: #3d3d3d;
  line-height: 1.563vw;
  display: block;
  text-align: center;
`
export default FirstScreen
