import styled from '@emotion/styled'
import Image from 'components/Image'
import { useState, useEffect } from 'react'
import { gsap } from 'gsap'
import { useNavigate } from 'react-router'

const FirstScreenH5 = () => {
  const [isBlack, setIsBlack] = useState<any>(true)
  const [isShowActive, setIsShowActive] = useState<any>(true)

  window.addEventListener('scroll', function () {
    // 获取当前滚动高度
    var scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

    const screenHeight = window.innerHeight
    const vh = screenHeight / 100

    // 检查滚动高度是否达到阈值
    if (scrollHeight >= 50 * vh) {
      if (isBlack) {
        setIsBlack(false)
      }
    } else {
      if (!isBlack) {
        setIsBlack(true)
      }
    }
  })

  useEffect(() => {
    // 创建 GSAP 动画
    const tl = gsap.timeline({ repeat: -1, yoyo: true })
    tl.to('.belowimg', { duration: 0.3, y: 5 })

    // const t2 = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.test-wrap',
    //     start: 'top top',
    //     end: '1',
    //     scrub: 1,
    //   },
    // })

    // t2.to('.activitybox', { opacity: 0 })

    // 在组件卸载时清除动画
    return () => {
      tl.kill()
      // t2.kill()
    }
  }, [])

  const navigate = useNavigate()
  const handleGame = (e: any) => {
    e.stopPropagation()
    navigate('/game')
  }
  const handleMint = (e: any) => {
    e.stopPropagation()
    navigate('/skytopia')
  }

  return (
    <>
      <Wrap className='test-wrap'>
        <Screen>
          {/* <LogoImg className='LogoImg' folder='index2/FirstScreenH5' src='marsxyz.png' /> */}
          <Logo folder='index2/FirstScreenH5' src='marsxyz.png' />
          <BelowBox style={{ opacity: isBlack ? 1 : 0 }}>
            <BelowImg className='belowimg' folder='index2/FirstScreenH5' src='below.png' />
            <BelowVal>SCROLL</BelowVal>
          </BelowBox>
        </Screen>

        <StickyScene>
          {/* <BigLogoImg className='biglogo' folder='index2/FirstScreenH5' src='logoImg.png' /> */}
          <BigLogo folder='index2/FirstScreenH5' src='marsxyz.png' />
          <Title className='exit-animation'>Welcome to MarsXYZ</Title>
          <SmallTitle className='exit-animation'>MarsXYZ is an organization that explores Mars ecology and future space economy, involving Mars immigration, space economy and technological development including AI, Game, IM communications, cryptocurrency, etc.</SmallTitle>

          <Tips className='exit-animation'>Skytopia is a web3 entertainment brand that connects games and communities. It combines different forms of entertainment like gaming, animation, novels to create an immersive entertainment metaverse for users.</Tips>
          <Tips className='exit-animation'>Based on Web3 technology, we hope to break down the barriers between creators and consumers. Let's build the Skytopia Universe together.</Tips>
        </StickyScene>
      </Wrap>
      {/* {isShowActive && (
        <ActivityBox className="activitybox">
          <GameDownBtn folder="index2/FirstScreenH5" onClick={handleGame} src="GameBtn"></GameDownBtn>
          <ActiveityClose
            folder="index2/FirstScreenH5"
            onClick={() => {
              setIsShowActive(false)
            }}
            src="H5btn_guan@2x"
          ></ActiveityClose>
        </ActivityBox>
      )} */}
    </>
  )
}
const Wrap = styled.div`
  width: 100vw;
  min-height: 215vh;
  position: relative;
  overflow: hidden;
`
const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`

const LogoImg = styled(Image)`
  width: 37.067vw;
  height: 40.533vw;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: right top;
`
const Logo = styled(Image)`
  width: 40.067vw;
  height: 40.067vw;
  position: absolute;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
`

const StickyScene = styled.div`
  width: 100%;
  height: 188vw;
  position: relative;
  overflow: hidden;
  padding-bottom: 6.133vw;
  box-sizing: border-box;
`

const BigLogoImg = styled(Image)`
  width: 47.6vw;
  height: 52vw;
  position: absolute;
  top: 21.2vw;
  left: 26.933vw;
`
const BigLogo = styled(Image)`
  width: 40.4vw;
  height: 40.4vw;
  position: absolute;
  left: 50%;
  top: 50vw;
  transform: translate(-50%, -50%);
`
const Title = styled.div`
  width: 94.933vw;
  height: 10.8vw;
  font-size: 8vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #3d3d3d;
  line-height: 10.8vw;
  margin: 84.133vw auto 4.267vw;
`

const SmallTitle = styled.div`
  width: 94.933vw;
  min-height: 28.133vw;
  font-size: 4.8vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 5.333vw;
  margin: 0 auto 3.867vw;
`
const Tips = styled.div`
  width: 94.933vw;
  height: 25.333vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #7b7b7b;
  line-height: 5.067vw;
  margin: 0 auto 3.867vw;
`

const BelowBox = styled.div`
  width: 18.933vw;
  height: 15.067vw;
  position: fixed;
  top: 60vh;
  left: 38.5vw;
  z-index: 1111;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const BelowImg = styled(Image)`
  width: 3.867vw;
  height: 3.867vw;
  display: block;
  margin: 3.2vw auto 1.467vw;
`
const BelowVal = styled.div`
  width: 84px;
  height: 7.333vw;
  font-size: 2.933vw;
  font-family: Albert Sans-Regular, Albert Sans;
  color: #3d3d3d;
  line-height: 7.333vw;
  text-align: center;
`

const ActivityBox = styled.div`
  position: fixed;
  bottom: 0vh;
  left: 0;
  width: 91.733vw;
  height: 56.5vw;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  padding-left: 8.267vw;
  z-index: 1300;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
const GameDownBtn = styled(Image)`
  width: 83.467vw;
  height: 36.133vw;
  margin-right: 1.2vw;
  cursor: pointer;
`
const MintBtn = styled(Image)`
  width: 83.467vw;
  height: 36.133vw;
  cursor: pointer;
`
const ActiveityClose = styled(Image)`
  width: 10.133vw;
  height: 10.133vw;
  position: absolute;
  margin: 0 auto;
  top: 41.467vw;
  left: 50%;
  transform: translateX(-50%);
`
export default FirstScreenH5
